function objectWithoutProperties (obj, exclude) { var target = {}; for (var k in obj) if (Object.prototype.hasOwnProperty.call(obj, k) && exclude.indexOf(k) === -1) target[k] = obj[k]; return target; }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"pickup-time-list"},[_c('div',{staticClass:"flex flex-row gap-4 items-center mb-3"},[_c('div',{staticClass:"flex flex-col gap-2 w-2/5"},[_c('span',{staticClass:"text-sm text-bgMatterhorn font-semibold"},[_vm._v("Cari")]),_c('TextInput',{attrs:{"type":"search","placeholder":"Nama Kota, Nama Rute"},on:{"input":_vm.onSearch},model:{value:(_vm.parameters.search),callback:function ($$v) {_vm.$set(_vm.parameters, "search", $$v)},expression:"parameters.search"}})],1),_c('div',{staticClass:"flex flex-col gap-2 w-1/5"},[_c('span',{staticClass:"text-sm text-bgMatterhorn font-semibold"},[_vm._v("Status")]),_c('DropdownSelect',{attrs:{"options":_vm.statusOptions,"placeholder":"Pilih Status"},on:{"input":_vm.onFilter},model:{value:(_vm.parameters.status),callback:function ($$v) {_vm.$set(_vm.parameters, "status", $$v)},expression:"parameters.status"}})],1)]),_c('div',{class:['pickup-time-table', { default: _vm.parameters.page === 1 }]},[_c('DataTableV2',{attrs:{"headers":_vm.headers,"dataItems":_vm.listData},scopedSlots:_vm._u([{key:"header-1",fn:function(ref){
var rest = objectWithoutProperties( ref, [] );
var data = rest;
return [_c('div',{staticClass:"flex flex-row gap-5 items-center cursor-pointer",attrs:{"data-testid":"cancel-validation-list__table-sort-courier-name"},on:{"click":function($event){return _vm.onTableSort(_vm.enumSortFields.CITY)}}},[_c('span',{staticClass:"w-20 sort-field"},[_vm._v(_vm._s(data.data.title))]),_c('div',{staticClass:"flex flex-col table-sort"},[_c('div',{staticClass:"ascending-icon",class:{
                'selected-sort': _vm.selectedSort === _vm.enumSelectedSort.CITY_ASC,
              }},[_c('AscendingIcon')],1),_c('div',{staticClass:"descending-icon",class:{
                'selected-sort': _vm.selectedSort === _vm.enumSelectedSort.CITY_DESC,
              }},[_c('AscendingIcon')],1)])])]}},{key:"header-2",fn:function(ref){
              var rest = objectWithoutProperties( ref, [] );
              var data = rest;
return [_c('div',{staticClass:"flex flex-row gap-5 items-center cursor-pointer",attrs:{"data-testid":"cancel-validation-list__table-sort-courier-name"},on:{"click":function($event){return _vm.onTableSort(_vm.enumSortFields.ROUTE)}}},[_c('span',{staticClass:"w-24 sort-field"},[_vm._v(_vm._s(data.data.title))]),_c('div',{staticClass:"flex flex-col table-sort"},[_c('div',{staticClass:"ascending-icon",class:{
                'selected-sort': _vm.selectedSort === _vm.enumSelectedSort.ROUTE_ASC,
              }},[_c('AscendingIcon')],1),_c('div',{staticClass:"descending-icon",class:{
                'selected-sort': _vm.selectedSort === _vm.enumSelectedSort.ROUTE_DESC,
              }},[_c('AscendingIcon')],1)])])]}},{key:"header-3",fn:function(ref){
              var rest = objectWithoutProperties( ref, [] );
              var data = rest;
return [_c('div',{staticClass:"flex flex-row gap-5 items-center cursor-pointer",attrs:{"data-testid":"cancel-validation-list__table-sort-courier-name"},on:{"click":function($event){return _vm.onTableSort(_vm.enumSortFields.PERIODE_START)}}},[_c('span',{staticClass:"w-28 sort-field"},[_vm._v(_vm._s(data.data.title))]),_c('div',{staticClass:"flex flex-col table-sort"},[_c('div',{staticClass:"ascending-icon",class:{
                'selected-sort':
                  _vm.selectedSort === _vm.enumSelectedSort.PERIODE_START_ASC,
              }},[_c('AscendingIcon')],1),_c('div',{staticClass:"descending-icon",class:{
                'selected-sort':
                  _vm.selectedSort === _vm.enumSelectedSort.PERIODE_START_DESC,
              }},[_c('AscendingIcon')],1)])])]}},{key:"header-4",fn:function(ref){
              var rest = objectWithoutProperties( ref, [] );
              var data = rest;
return [_c('div',{staticClass:"flex flex-row gap-5 items-center cursor-pointer",attrs:{"data-testid":"cancel-validation-list__table-sort-courier-name"},on:{"click":function($event){return _vm.onTableSort(_vm.enumSortFields.PERIODE_END)}}},[_c('span',{staticClass:"w-32 sort-field"},[_vm._v(_vm._s(data.data.title))]),_c('div',{staticClass:"flex flex-col table-sort"},[_c('div',{staticClass:"ascending-icon",class:{
                'selected-sort':
                  _vm.selectedSort === _vm.enumSelectedSort.PERIODE_END_ASC,
              }},[_c('AscendingIcon')],1),_c('div',{staticClass:"descending-icon",class:{
                'selected-sort':
                  _vm.selectedSort === _vm.enumSelectedSort.PERIODE_END_DESC,
              }},[_c('AscendingIcon')],1)])])]}},{key:"0",fn:function(ref){
              var rest = objectWithoutProperties( ref, [] );
              var data = rest;
return [_c('div',[_vm._v(" "+_vm._s(_vm.parameters.page === 1 ? data.data.value - 1 : data.data.value)+" ")])]}},{key:"5",fn:function(ref){
              var rest = objectWithoutProperties( ref, [] );
              var data = rest;
return [_c('Badge',{staticClass:"w-28 py-1 text-center",attrs:{"customPadding":"","type":_vm.pickupTimeStatusMapper(data.data.value).color}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.pickupTimeStatusMapper(data.data.value).text)}})])]}},{key:"6",fn:function(ref){
              var rest = objectWithoutProperties( ref, [] );
              var data = rest;
return [_c('div',{staticClass:"flex flex-row gap-4"},[_c('router-link',{staticClass:"text-sm text-bgPrimary font-semibold",attrs:{"to":{
              name: 'PickupTimeManagementDetail',
              params: { id: data.data.value },
            }}},[_vm._v(" Lihat ")]),_c('router-link',{staticClass:"text-sm text-bgPrimary font-semibold",attrs:{"to":{
              name: 'PickupTimeManagementEdit',
              params: { id: data.data.value, default: data.data.isDefault },
            }}},[_vm._v(" Edit ")]),(!data.data.isDefault)?_c('Button',{staticClass:"text-sm text-bgPrimary font-semibold",attrs:{"buttonStyle":"outline-none"},on:{"click":function () { return _vm.onConfirmDelete(data.data.value, data.data.routes); }}},[_vm._v(" Hapus ")]):_vm._e()],1)]}}])})],1),_c('div',{staticClass:"mt-3"},[_c('PaginationNav',{staticClass:"mb-8 mt-2",attrs:{"page":_vm.parameters.page,"perPage":_vm.parameters.perPage,"lang":"id","enablePerPageOption":true,"perPageOptions":_vm.perPageOptions,"totalItem":_vm.controller.paginationData.totalItem},on:{"input":function () {
          _vm.fetchList()
        },"onChangeOption":_vm.onChangePerPage},model:{value:(_vm.parameters.page),callback:function ($$v) {_vm.$set(_vm.parameters, "page", $$v)},expression:"parameters.page"}})],1),_c('ModalConfirm',{attrs:{"visible":_vm.modalConfirmDeleteVisible,"title":("Yakin Hapus Data Rute " + _vm.deletedRoute + "?"),"description":("Data Route " + _vm.deletedRoute + " akan hilang"),"actionBtnLabel":"Ya, Hapus","cancelBtnLabel":"Cek Ulang"},on:{"action":_vm.onDelete,"cancel":function () {
        _vm.modalConfirmDeleteVisible = false
        _vm.deletedId = undefined
        _vm.deletedRoute = ''
      }}}),(_vm.controller.isLoading)?_c('LoadingOverlay'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }